import React from "react";

import { isBrowser } from "react-device-detect";

import {
  HeroComponent,
  TestimonialLargeComponent,
  TestimonialSmallComponent,
  TextureComponent,
  PerformanceComponent,
  SolutionsComponent,
  PartnersComponent,
  CrisisChartComponent,
  ReferenceComponent,
  BeatBenchmarkComponent,
  WhySmartwealthComponent,
  BestPortfolioComponent,
  PhilosophieComponent,
  LosungComponent,
  Uberuns1Component,
  Uberuns2Component,
  UnserzielComponent,
  UnserteamComponent,
  OurhistoryComponent,
  PrivacyPolicyComponent,
  CustomerInformationComponent,
  ImpressumComponent,
  investmentSliderComponent,
  YourAssetsComponent,
  ContactComponent,
  LookIntoFutureComponent,
  AdvantagesComponent,
  ProtectYourWealthComponent,
  OutstandingResultsSliderComponent,
  BlogFormattedTextBlock,
  BlogMultimedia,
  BlogTextBlock,
  BlogButtonBlock,
  PictureTextSliderComponent,
  NumberTextSliderComponent,
  OutstandingProductsComponent,
  AddedValuesComponent,
  StrategyComponent,
  BlackFullPageSliderComponents,
  TilesComponent,
  HoverCardComponent,
  BlueComponent,
  OurSolutionsComponent,
} from "./slices";

const SliceZone = ({ slices }) => {
  const sliceComponents = {
    hero_section: HeroComponent,
    largeTestimonial_section: TestimonialLargeComponent,
    largetestimonial_section: TestimonialLargeComponent,
    smalltestimonial_section: TestimonialSmallComponent,
    texture_section: TextureComponent,
    outstanding_results: OutstandingResultsSliderComponent,
    _9_investment_slider: investmentSliderComponent,
    _8_deserve_batter: YourAssetsComponent,
    _5_advantages: AdvantagesComponent,
    _6_future: LookIntoFutureComponent,
    protect_and_grow: ProtectYourWealthComponent,
    performance_section: PerformanceComponent,
    solutions_section: SolutionsComponent,
    partners_section: PartnersComponent,
    crisis_section: CrisisChartComponent,
    reference_section: ReferenceComponent,
    beatperformance_section: BeatBenchmarkComponent,
    whysmartwealth_section: WhySmartwealthComponent,
    bestportfolio_section: BestPortfolioComponent,
    philosophies_section: PhilosophieComponent,
    losung_section: LosungComponent,
    uberuns1_section: Uberuns1Component,
    uberuns2_section: Uberuns2Component,
    unserziel_section: UnserzielComponent,
    unserteam_section: UnserteamComponent,
    ourhistory_section: OurhistoryComponent,
    contact_section: ContactComponent,
    privacypolicy_section: PrivacyPolicyComponent,
    imprint: ImpressumComponent,
    customerinformation_section: CustomerInformationComponent,
    blog_multimedia: BlogMultimedia,
    blog_formattedtextblock: BlogFormattedTextBlock,
    blog_textsection: BlogTextBlock,
    blog_buttoncont: BlogButtonBlock,
    picturetextslidersection: PictureTextSliderComponent,
    numberslidersection: NumberTextSliderComponent,
    outstanding_products: OutstandingProductsComponent,
    added_values: AddedValuesComponent,
    strategy_section: StrategyComponent,
    black_fullpage_slider: BlackFullPageSliderComponents,
    tile_section: TilesComponent,
    hover_card_section: HoverCardComponent,
    blue_section: BlueComponent,
    our_solutions: OurSolutionsComponent,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];

    if (SliceComponent) {
      if (isBrowser) {
        return (
          <div className="section">
            <SliceComponent
              slice={slice}
              uniqKey={`slice-${index}`}
              key={`slice-${index}`}
            />
          </div>
        );
      } else {
        return (
          <SliceComponent
            slice={slice}
            uniqKey={`slice-${index}`}
            key={`slice-${index}`}
          />
        );
      }
    }
    return null;
  });
};

export default SliceZone;
